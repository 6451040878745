import React from "react";
import { Button, DropDownButton, SelectBox, TextBox, Toolbar } from "devextreme-react";
import { Item } from "devextreme-react/toolbar";
import { ItemClickEvent } from "devextreme/ui/drop_down_button";
import { globalPopupManager, enums, DataMap, ListItem, dataSources, fdate, today } from "../../base";
import { AreaRef, CustomerBatchModel, CustomerCondition, CustomerModel, EmployeeRef, OrganizationRef } from "../../models";
import { emptyDataSources } from "../../api/data-source-helper";
import { areaService, employeeService, organizationService } from "../../api";
import { DurationPopup, OrgTreeDropDown } from "../../components";
import { ValueChangedEvent } from "devextreme/ui/select_box";
import { ValueChangedEvent as TextChangedEvent } from "devextreme/ui/text_box";

export interface CustomerToolbarProps {
    permissions: DataMap<boolean>;
    onButtonClick: (customer: CustomerModel, name: string) => void;
    onBatchButtonClick: (customers: CustomerBatchModel[], name: string) => void;
    onSpecialFiltering: (condition: CustomerCondition, filterValue: any) => void;
    onFiltering: (filterValue: any) => void;
}

interface CustomerToolbarState {
    customer: CustomerModel;
    batchCustomers: CustomerBatchModel[];
    specialFlag?: string;
    specialFlagValue?: any[];
    filterButtonText: string;
    orgs: OrganizationRef[];
    allDelivers: EmployeeRef[];
    delivers: EmployeeRef[];
    counties: AreaRef[];
    allStreets: AreaRef[];
    streets: AreaRef[];
    organizationId?: number;
    deliverId?: string;
    countyId?: number;
    streetId?: number;
    hasContract?: boolean;
    hasIdNo?: boolean;
    checkValid?: boolean;
    status?: string;
    customerKey?: string;
    phone?: string;
    weiXinBound?: boolean;
    alerter?: string;
    isStdAddress?: boolean;
}

export default class CustomerToolbar extends React.Component<CustomerToolbarProps, CustomerToolbarState> {
    filterItems: ListItem[] = [
        { text: "无", value: "" },
        { text: "近期有叫气", value: 'RecentOrder' },
        { text: "长期未叫气", value: 'Idle' },
        { text: "开户未叫气", value: 'NoOrder' },
        { text: "电话有误", value: 'InvalidPhone' },
        { text: "证件号有误", value: 'InvalidIdNo' },
        { text: "地址不规范", value: 'InvalidAddress' },
        { text: "有标准地址照片", value: "HasStdAddressPhoto" }
    ];

    durationPopup = React.createRef<DurationPopup>();

    constructor(props: Readonly<CustomerToolbarProps>) {
        super(props);

        this.state = {
            customer: {},
            batchCustomers: [],
            filterButtonText: "特殊过滤",
            orgs: [],
            allDelivers: [],
            delivers: [],
            counties: [],
            allStreets: [],
            streets: [],
            status: enums.customerStatus.active
        };

        this.showCallCenter = this.showCallCenter.bind(this);
        this.onSpecialChanged = this.onSpecialChanged.bind(this);
        this.onSpecialFiltering = this.onSpecialFiltering.bind(this);
        this.onOrganizationChanged = this.onOrganizationChanged.bind(this);
        this.onDeliverChanged = this.onDeliverChanged.bind(this);
        this.onCountyChanged = this.onCountyChanged.bind(this);
        this.onStreetChanged = this.onStreetChanged.bind(this);
        this.onContractChanged = this.onContractChanged.bind(this);
        this.onIdNoChanged = this.onIdNoChanged.bind(this);
        this.onFiltering = this.onFiltering.bind(this);
        this.onCheckValidChanged = this.onCheckValidChanged.bind(this);
        this.onStatusChanged = this.onStatusChanged.bind(this);
        this.onCustomerChanged = this.onCustomerChanged.bind(this);
        this.onPhoneChanged = this.onPhoneChanged.bind(this);
        this.onWeiXinBoundChanged = this.onWeiXinBoundChanged.bind(this);
        this.onAlerterChanged = this.onAlerterChanged.bind(this);
        this.onIsStdAddressChanged = this.onIsStdAddressChanged.bind(this);
    }

    componentDidMount() {
        this.loadDataSources();
    }

    async loadDataSources() {
        setTimeout(this.onSpecialFiltering, 100);

        const dataSources = emptyDataSources();

        await Promise.all([
            (async () => dataSources.orgs = await organizationService.getRefs())(),
            (async () => dataSources.employees = await employeeService.getDelivers())(),
            (async () => dataSources.counties = await areaService.getCounties())(),
            (async () => dataSources.streets = await areaService.getStreets())(),
        ]);

        this.setState({
            orgs: dataSources.orgs,
            allDelivers: dataSources.employees,
            delivers: dataSources.employees,
            counties: dataSources.counties,
            allStreets: dataSources.streets,
            streets: dataSources.streets
        });
    }

    setCustomer(customer: CustomerModel) {
        this.setState({ customer: customer });
    }

    setBatchCustomers(customers: CustomerBatchModel[]) {
        this.setState({ batchCustomers: customers });
    }

    showCallCenter() {
        globalPopupManager.show("callcenter", { id: this.state.customer.id });
    }

    async onSpecialChanged(e: ItemClickEvent) {
        let value: number | undefined = undefined;

        if (e.itemData.value === "RecentOrder" || e.itemData.value === "Idle") {
            this.durationPopup.current?.show({ endDate: today() }, (startDate, endDate) => {
                const startDateString = fdate(startDate, true), 
                    endDateString = fdate(endDate, true);

                this.setState({ 
                    specialFlag: e.itemData.value,
                    specialFlagValue: [startDateString, endDateString],
                    filterButtonText: `${e.itemData.text}：${startDateString}~${endDateString}`
                }, this.onSpecialFiltering);
            });

            return;
            // const pvalue = await prompt2("请输入查询的天数，空则表示使用默认");
            // if (pvalue?.match(/^\d+$/g)) {
            //     value = parseInt(pvalue, 10);
            // }
        }

        const filterButtonText = (e.itemData.value ? `${e.itemData.text}` : "");

        this.setState({
            specialFlag: e.itemData.value,
            specialFlagValue: value,
            filterButtonText: filterButtonText
        }, this.onSpecialFiltering);
    }

    onSpecialFiltering() {
        this.props.onSpecialFiltering({
            specialFlag: this.state.specialFlag,
            specialFlagValue: this.state.specialFlagValue,
            checkValid: this.state.checkValid,
            weiXinBound: this.state.weiXinBound
        }, this.getFilterValue());
    }

    onOrganizationChanged(organizationId?: number) {
        const delivers = organizationId ?
            this.state.allDelivers.filter(d => d.organizationId === organizationId) :
            this.state.allDelivers;

        const deliverId = delivers.find(d => d.id === this.state.deliverId) ? this.state.deliverId : undefined;

        this.setState({
            organizationId: organizationId,
            delivers: delivers,
            deliverId: deliverId
        });
    }

    onFiltering() {
        this.onSpecialFiltering();
    }

    private getFilterValue() {
        const filters: any[] = [];
        const { organizationId, deliverId, countyId, streetId, status, 
            hasContract, hasIdNo, customerKey, phone, alerter, isStdAddress } = this.state;

        if (organizationId) {
            filters.push(["organizationId", "=", organizationId]);
        }
        if (deliverId) {
            filters.push(["deliverId", "=", deliverId]);
        }
        if (countyId) {
            filters.push(["areaId", "=", countyId]);
        }
        if (streetId) {
            filters.push(["streetId", "=", streetId]);
        }
        if (status) {
            filters.push(["status", "=", status]);
        }
        if (customerKey) {
            filters.push([
                ["gasCode", "contains", customerKey],
                "or",
                ["name", "contains", customerKey]
            ]);
        }
        if (phone) {
            filters.push([
                ["phoneNumber", "contains", phone],
                "or",
                ["phoneNumber2", "contains", phone]
            ])
        }
        if (typeof (hasContract) === "boolean") {
            if (hasContract) {
                filters.push([["contractNumber", "<>", null], "and", ["contractNumber", "<>", ""]]);
            } else {
                filters.push([["contractNumber", "=", null], "or", ["contractNumber", "=", ""]]);
            }
        }
        if (typeof (hasIdNo) === "boolean") {
            if (hasIdNo) {
                filters.push([["idNumber", "<>", null], "and", ["idNumber", "<>", ""]]);
            } else {
                filters.push([["idNumber", "=", null], "or", ["idNumber", "=", ""]]);
            }
        }
        if (alerter) {
            if (alerter === 'Unknown') {    // Unknown表示查询空
                filters.push([["alerter", "=", null], "or", ["alerter", "=", ""]]);
            } else {
                filters.push(["alerter", "=", alerter]);
            }
        }
        if (typeof(isStdAddress) === "boolean") {
            filters.push(["isStdAddress", "=", isStdAddress]);
        }

        return filters.length > 0 ? filters : null;
    }

    onDeliverChanged(e: ValueChangedEvent) {
        this.setState({ deliverId: e.value });
    }

    onCountyChanged(e: ValueChangedEvent) {
        const streets = e.value ?
            this.state.allStreets.filter(s => s.parentId === e.value) :
            this.state.allStreets;

        const streetId = streets.find(s => s.id === this.state.streetId) ? this.state.streetId : undefined;

        this.setState({
            countyId: e.value,
            streets: streets,
            streetId: streetId
        });
    }

    onStreetChanged(e: ValueChangedEvent) {
        this.setState({ streetId: e.value });
    }

    onContractChanged(e: ValueChangedEvent) {
        this.setState({ hasContract: e.value });
    }

    onIdNoChanged(e: ValueChangedEvent) {
        this.setState({ hasIdNo: e.value });
    }

    onCheckValidChanged(e: ValueChangedEvent) {
        this.setState({ checkValid: e.value });
    }

    onStatusChanged(e: ValueChangedEvent) {
        this.setState({ status: e.value });
    }

    onCustomerChanged(e: TextChangedEvent) {
        this.setState({ customerKey: e.value });
    }

    onPhoneChanged(e: TextChangedEvent) {
        this.setState({ phone: e.value });
    }

    onWeiXinBoundChanged(e: ValueChangedEvent) {
        this.setState({ weiXinBound: e.value });
    }

    onAlerterChanged(e: ValueChangedEvent) {
        this.setState({ alerter: e.value });
    }

    onIsStdAddressChanged(e: ValueChangedEvent) {
        this.setState({ isStdAddress: e.value });
    }

    render() {
        const { customer, batchCustomers, filterButtonText, orgs, delivers, counties, streets, 
            organizationId, deliverId, countyId, streetId, hasContract, hasIdNo, 
            checkValid, status, customerKey, phone, weiXinBound, alerter, isStdAddress } = this.state;

        const permissions = this.props.permissions;

        const batch0 = batchCustomers[0];
        const statusButtonText = batch0?.status === enums.customerStatus.inactive ? "恢复" : "退户";
        const statusEnabled = batchCustomers.length > 0 && !batchCustomers.find(c => c.status !== batch0.status);
        const lockButtonText = batch0?.locked ? "解锁" : "锁定";
        const lockEnabled = batchCustomers.length > 0 && !batchCustomers.find(c => c.locked !== batch0?.locked);
        const stdAddressButtonEnabled = batchCustomers.length > 0 && !batchCustomers.find(c => c.isStdAddress !== batch0.isStdAddress);
        const stdAddressButtonText = batch0?.isStdAddress ? "非标准地址" : "是标准地址";

        return (
            <div className={"page-toolbar"}>
                <DurationPopup ref={this.durationPopup} />
                <Toolbar>
                    <Item location={"before"}>
                        <DropDownButton
                            stylingMode="contained"
                            text={filterButtonText}
                            icon="filter"
                            items={this.filterItems}
                            dropDownOptions={{ width: 200 }}
                            displayExpr={"text"}
                            onItemClick={this.onSpecialChanged}>
                        </DropDownButton>
                    </Item>
                    <Item location={"after"} locateInMenu="auto" visible={permissions["AddOrder"]}>
                        <Button
                            disabled={!customer?.id}
                            type="default"
                            text="呼叫中心"
                            onClick={this.showCallCenter} />
                    </Item>
                    <Item location={"after"} locateInMenu={"auto"} visible={permissions[enums.stdActions.create]}>
                        <Button
                            text="新增"
                            onClick={() => this.props.onButtonClick({}, "add")} />
                    </Item>
                    <Item location={"after"} locateInMenu="auto" visible={permissions[enums.stdActions.update]}>
                        <Button
                            disabled={!customer?.id}
                            text="编辑"
                            onClick={() => this.props.onButtonClick(customer, "edit")} />
                    </Item>
                    <Item location={"after"} locateInMenu="auto" visible={permissions[enums.stdActions.update]}>
                        <Button
                            disabled={!statusEnabled}
                            text={statusButtonText}
                            onClick={() => this.props.onBatchButtonClick(batchCustomers, "changeStatus")} />
                    </Item>
                    <Item location={"after"} locateInMenu="auto" visible={permissions["Lock"]}>
                        <Button
                            disabled={!lockEnabled}
                            text={lockButtonText}
                            onClick={() => this.props.onBatchButtonClick(batchCustomers, "switchLock")} />
                    </Item>
                    <Item location={"after"} locateInMenu="auto" visible={permissions["IssueDanger"]}>
                        <Button
                            disabled={!customer?.id}
                            text="下发隐患"
                            onClick={() => this.props.onButtonClick(customer, "issueDanger")} />
                    </Item>
                    <Item location={"after"} locateInMenu={"auto"} visible={permissions["StdAddress"]}>
                        <Button
                            disabled={!stdAddressButtonEnabled}
                            text={stdAddressButtonText}
                            onClick={() => this.props.onBatchButtonClick(batchCustomers, "switchStdAddress")} />
                    </Item>
                    <Item location={"after"} locateInMenu="auto">
                        <Button
                            disabled={!batchCustomers.length}
                            text="打印公示牌二维码"
                            onClick={() => this.props.onBatchButtonClick(batchCustomers, "printSecurityCode")} />
                    </Item>
                    <Item location={"after"} locateInMenu="auto" visible={permissions["AddTrouble"]}>
                        <Button
                            disabled={!customer?.id}
                            text="报修"
                            onClick={() => this.props.onButtonClick(customer, "addTrouble")} />
                    </Item>
                    <Item location={"after"} locateInMenu="auto" visible={permissions[enums.stdActions.update]}>
                        <Button
                            disabled={!customer?.id}
                            text="卡挂失"
                            onClick={() => this.props.onButtonClick(customer, "changeGasCode")} />
                    </Item>
                    <Item location={"after"} locateInMenu="auto" visible={permissions["UnbindWeiXin"]}>
                        <Button
                            disabled={!customer?.id || !(customer as any).weiXinBound}
                            text="解绑微信"
                            onClick={() => this.props.onButtonClick(customer, "unbindWeiXin")} />
                    </Item>
                    {/* <Item location={"after"} locateInMenu="auto" visible={permissions["Barcode"]}>
                        <Button
                            disabled={!customer?.id}
                            text="查看条形码"
                            onClick={() => this.props.onButtonClick(customer, "barcode")} />
                    </Item> */}
                </Toolbar>
                <Toolbar>
                    <Item location={"before"} locateInMenu="auto">
                        <SelectBox
                            value={hasContract}
                            dataSource={hasContractSource}
                            displayExpr="text"
                            valueExpr="value"
                            showClearButton={true}
                            width={100}
                            placeholder="有否合同"
                            onValueChanged={this.onContractChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu="auto">
                        <SelectBox
                            value={hasIdNo}
                            dataSource={hasIdNoSource}
                            displayExpr="text"
                            valueExpr={"value"}
                            showClearButton={true}
                            width={100}
                            placeholder="有否证号"
                            onValueChanged={this.onIdNoChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu="auto">
                        <SelectBox
                            value={checkValid}
                            dataSource={checkValidSource}
                            displayExpr="text"
                            valueExpr={"value"}
                            showClearButton={true}
                            width={100}
                            placeholder={"检查状态"}
                            onValueChanged={this.onCheckValidChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu="auto">
                        <SelectBox
                            value={weiXinBound}
                            dataSource={dataSources.trueFalseList}
                            displayExpr="text"
                            valueExpr={"value"}
                            showClearButton={true}
                            width={100}
                            placeholder="绑定微信"
                            onValueChanged={this.onWeiXinBoundChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu="auto">
                        <SelectBox
                            value={alerter}
                            dataSource={alerterSource}
                            displayExpr="text"
                            valueExpr={"value"}
                            showClearButton={true}
                            width={100}
                            placeholder="报警器"
                            onValueChanged={this.onAlerterChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu={"auto"}>
                        <SelectBox
                            value={isStdAddress}
                            dataSource={dataSources.trueFalseList}
                            displayExpr={"text"}
                            valueExpr={"value"}
                            showClearButton={true}
                            width={100}
                            placeholder="标准地址"
                            onValueChanged={this.onIsStdAddressChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu="auto">
                        <SelectBox
                            value={status}
                            dataSource={dataSources.customerStatus}
                            displayExpr="text"
                            valueExpr={"value"}
                            showClearButton={true}
                            width={100}
                            placeholder="状态"
                            onValueChanged={this.onStatusChanged} />
                    </Item>
                </Toolbar>
                <Toolbar>
                    <Item location={"before"} locateInMenu={"auto"} visible={orgs.length > 1}>
                        <OrgTreeDropDown
                            organizationId={organizationId}
                            organizations={orgs}
                            placeholder="供应站"
                            width={180}
                            onValueChanged={this.onOrganizationChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu={"auto"}>
                        <SelectBox
                            value={deliverId}
                            dataSource={delivers}
                            displayExpr="nameAndCode"
                            valueExpr="id"
                            searchEnabled={true}
                            showClearButton={true}
                            width={160}
                            placeholder="送气工"
                            onValueChanged={this.onDeliverChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu={"auto"}>
                        <SelectBox
                            value={countyId}
                            dataSource={counties}
                            displayExpr="name"
                            valueExpr={"id"}
                            showClearButton={true}
                            width={100}
                            placeholder="行政区域"
                            onValueChanged={this.onCountyChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu={"auto"}>
                        <SelectBox
                            value={streetId}
                            dataSource={streets}
                            displayExpr="name"
                            valueExpr={"id"}
                            searchEnabled={true}
                            showClearButton={true}
                            width={100}
                            placeholder="街道"
                            onValueChanged={this.onStreetChanged} />
                    </Item>
                    <Item location={"before"} locateInMenu="auto">
                        <TextBox
                            value={customerKey}
                            showClearButton={true}
                            placeholder="客户名称或卡号"
                            onValueChanged={this.onCustomerChanged}
                            onEnterKey={this.onFiltering} />
                    </Item>
                    <Item location={"before"} locateInMenu="auto">
                        <TextBox
                            value={phone}
                            showClearButton={true}
                            placeholder="电话"
                            onValueChanged={this.onPhoneChanged}
                            onEnterKey={this.onFiltering} />
                    </Item>
                    <Item location={"before"} locateInMenu="false">
                        <Button
                            text="查询"
                            type="default"
                            onClick={this.onFiltering} />
                    </Item>
                </Toolbar>
            </div>
        );
    }
}

const hasContractSource: ListItem<boolean>[] = [
    { value: true, text: "有合同" },
    { value: false, text: "无合同" }
]

const hasIdNoSource: ListItem<boolean>[] = [
    { value: true, text: "有证件号" },
    { value: false, text: "无证件号" }
]

const checkValidSource: ListItem<boolean>[] = [
    { value: true, text: "有安检" },
    { value: false, text: "无安检/过期" }
]

const alerterSource: ListItem[] = [
    { value: "Unknown", text: "未填写" },
    ...dataSources.alerterStatus
]